
(function(){

    // slider
    jQuery('.p-top-visual-slider ul').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        accessibility: false,
        arrows: false,
        dots: false,
        infinite: true,
        centerMode: true,
        variableWidth: true,
        autoplay: true,
        autoplaySpeed: 0,
        pauseOnHover: false,
        cssEase: 'linear',
        speed: 12000,

        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    // news
    let setWidth = 767;
    let width = jQuery(window).width();
    let newsList = jQuery('.p-news-list');
    let isNewsSlick = false;

    let newsSlicksetting = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    if(width <= setWidth){
        newsList.slick(newsSlicksetting);
        isNewsSlick = true;
    }else if(isNewsSlick !== false) {
        newsList.slick('unslick');
        isNewsSlick = false;
    }

    jQuery(window).resize(function() {
        width = jQuery(window).width();
        if(width <= setWidth){

            if(isNewsSlick === false) {
                newsList.slick(newsSlicksetting);
                isNewsSlick = true;
            }

        }else if(isNewsSlick !== false) {
            newsList.slick('unslick');
            isNewsSlick = false;
        }
    });
}());